import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import format from "date-fns/format";
import Img from "gatsby-image";
import { useMeasure } from "react-use";
import { Box, Flex, Card as RebassCard, Text, Heading } from "rebass";
import Logo from "@icons/opay-logo.svg";

const Image = styled(Img)`
  height: 100%;
  width: 100%;
`;

export const Card = ({ full, item }) => {
  const { author, category, excerpt, image, published_at, slug, title } = item;
  const [ref, { width }] = useMeasure();
  const poster = image?.childImageSharp?.fluid;

  return (
    <RebassCard>
      <Flex flexDirection={["column", null, full ? "row" : "column"]} mx="-1.5rem">
        <Box px="1.5rem" flex={1} mb={full ? ["2.5rem", null, 0] : "2.5rem"}>
          <Box height={[width * 0.65, null, "100%", width * 0.55]} maxHeight="35rem" ref={ref} width="100%">
            {poster ? (
              <Image
                fluid={poster}
                objectFit="cover"
                objectPosition="50% 50%"
                style={{ maxHeight: "unset !important" }}
              />
            ) : (
              <Flex alignItems="center" justifyContent="center" bg="banner" height="100%">
                <Logo width={128} height={60} />
              </Flex>
            )}
          </Box>
        </Box>

        <Box px="1.5rem" flex={1}>
          <Flex
            alignItems={["flex-start", "center"]}
            flexDirection={["column", "row"]}
            flexWrap="wrap"
            mb={["3.5rem", null, "5rem"]}
          >
            <Text variant="body">By {author?.name}</Text>
            <Box width="1px" height="2rem" bg="text" mx="1.5rem" display={["none", "block"]} />

            <Text
              as={Link}
              color="primary"
              to={`/category/${category?.slug}`}
              variant="body"
              sx={{
                textDecoration: "none",
                "&:visited": {
                  color: "primary",
                },
              }}
            >
              {category?.name}
            </Text>

            <Box
              bg="header"
              display={["none", "block"]}
              height="6px"
              mx="1rem"
              sx={{ borderRadius: "50%" }}
              width="6px"
            />

            <Text variant="body" opacity="0.4">
              {format(new Date(published_at), "MMM dd, yyyy")}
            </Text>
          </Flex>

          <Box mb={["1.5rem"]}>
            <Heading
              as={Link}
              to={`/post/${slug}`}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
                "&:active": {
                  textDecoration: "underline",
                },
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {title}
            </Heading>
          </Box>

          <Box mb={["2rem", null, "2.8rem"]}>
            <Text variant="body">{excerpt}</Text>
          </Box>
        </Box>
      </Flex>
    </RebassCard>
  );
};

Card.propTypes = {
  full: PropTypes.bool,
  item: PropTypes.shape({
    author: PropTypes.shape({
      name: PropTypes.string,
    }),
    category: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
    excerpt: PropTypes.string,
    image: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
    published_at: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

Card.defaultProps = {
  full: false,
};
