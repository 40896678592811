import React from "react";
import styled from "@emotion/styled";
import Cloud from "../../assets/icons/cloud.svg";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-bottom: 10px;
`;

const FirstCloud = styled.svg``;

const SecondCloud = styled.svg`
  height: auto;
  margin-top: -30px;
  width: 100px;
  display: none;

  ${({ theme }) => theme.mq.md`
    display: block;
  `}
`;

const ThirdCloud = styled.svg`
  height: auto;
  margin-top: -5px;
  width: 80px;
`;

const Clouds = () => {
  return (
    <Wrapper>
      <FirstCloud as={Cloud} />
      <SecondCloud as={Cloud} />
      <ThirdCloud as={Cloud} />
    </Wrapper>
  );
};

export default Clouds;
