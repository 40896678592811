import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";
import styled from "@emotion/styled";
import FacebookIcon from "@icons/facebook-icon.svg";
import LinkedinIcon from "@icons/linkedin-icon.svg";
import TwitterIcon from "@icons/twitter-icon.svg";
import { share, openInNewTab } from "@lib/share";
import { Text } from "rebass";

const Wrapper = styled.div`
  order: 1;

  ${({ theme }) => theme.mq.lg`
    margin-top: 0;
    order: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
    width: auto;
  `}
`;

const IconWrapper = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: row;
`;

const Item = styled.div`
  cursor: pointer;
  svg {
    width: 2.5rem;
    height: 2.5rem;
  }

  &:not(:last-child) {
    margin-right: 1.5rem;
  }
`;

export const SocialShare = ({ title }) => {
  const { origin, pathname } = useLocation();
  const shareTitle = `Read more on "${title}" here:`;
  const sharelink = `${origin}${pathname}`;

  return (
    <Wrapper>
      <Text variant="body" fontSize="sm" fontWeight="bold" display={["none", null, null, "block"]} mb="1.5rem">
        SHARE THIS ARTICLE
      </Text>

      <IconWrapper>
        <Item onClick={() => openInNewTab(share.toFacebook(sharelink, shareTitle))}>
          <FacebookIcon />
        </Item>

        <Item onClick={() => openInNewTab(share.toTwitter(sharelink, shareTitle))}>
          <TwitterIcon />
        </Item>

        <Item onClick={() => openInNewTab(share.toLinkedin(sharelink, shareTitle))}>
          <LinkedinIcon />
        </Item>
      </IconWrapper>
    </Wrapper>
  );
};

SocialShare.propTypes = {
  title: PropTypes.string.isRequired,
};
