import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
// import { animated, useSpring } from "react-spring";
import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/core";
// import SuccessBadge from "@icons/success-badge.svg";
import { Box, Button, Flex, Heading, Text } from "rebass";
import { useOnFormSuccess } from "@hooks";

const rotate = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px 0;
  width: 100%;
  .svg-wrapper {
    margin-bottom: 40px;
  }

  ${({ theme }) => theme.mq.lg`
  .svg-wrapper {
    margin-bottom: 60px;
  }

  `}
`;

export const SubmitButton = styled(Button)`
  cursor: pointer;
  ${({ isLoading }) =>
    isLoading &&
    css`
      color: transparent !important;
      pointer-events: none;
      position: relative;

      &::after {
        content: "";
        animation: ${rotate} 500ms infinite linear;
        border: 2px solid #fff;
        border-radius: 50%;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        display: block;
        height: 1.2em;
        width: 1.2em;
        position: absolute;
        left: calc(50% - (1.2em / 2));
        top: calc(50% - (1.2em / 2));
        -webkit-transform-origin: center;
        transform-origin: center;
        position: absolute !important;
      }
    `}
`;

export const SuccessView = ({ style, text }) => {
  return (
    <Wrapper>
      {/* <animated.div className="svg-wrapper" style={{ opacity: style.opacity, transform: style.scaleTransform }}>
        <SuccessBadge />
      </animated.div>

      <animated.div style={{ opacity: style.opacity, transform: style.positionTransform }}>
        <Text textAlign="center">{text}</Text>
      </animated.div> */}
    </Wrapper>
  );
};

export const Newsletter = ({ bg, borderRadius }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useOnFormSuccess();

  // const animatedStyle = useSpring({
  //   config: { duration: 300 },
  //   scaleTransform: submitted ? `scale(1)` : "scale(0)",
  //   positionTransform: submitted ? "translateY(0)" : "translateY(-3px)",
  //   opacity: submitted ? 1 : 0,
  // });

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    if (!submitted) {
      setEmail("");
      setError("");
    }
  }, [submitted]);

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!email) return setError("Please input your email address.");
      setIsLoading(true);
      setError("");
      await axios(`${process.env.GATSBY_API_URL}/waitlist/blog`, {
        method: "post",
        data: { email },
      });
      setSubmitted(true);
    } catch (e) {
      setError(e?.response?.data?.message || "An error occured");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex
      bg={bg}
      px="2rem"
      py={["5rem", null, "7rem"]}
      sx={{
        borderRadius,
      }}
    >
      {submitted ? (
        <SuccessView text={`Thank you for subscribing!`} />
      ) : (
        <Flex maxWidth="60rem" flexDirection="column" mx="auto">
          <Heading textAlign="center" mb={["1rem", null, "1.5rem"]}>
            Get more stories
          </Heading>

          <Text textAlign="center" variant="body" color="text" mb={["3rem", null, "5rem"]}>
            Enter your email address to subscribe to our newsletter and get notified when we post a new article.
          </Text>

          <Box>
            <Flex flexDirection={["column", null, "row"]} as="form" onSubmit={onSubmit}>
              <Box
                as="input"
                name="email"
                onChange={onEmailChange}
                placeholder="Enter your email address"
                required
                type="email"
                value={email}
                variant="input"
              />
              <Box width={[0, null, "1.5rem"]} height={["2rem", null, 0]} />
              <SubmitButton onClick={onSubmit} variant="newsletter" isLoading={isLoading}>
                Subscribe
              </SubmitButton>
            </Flex>

            {error && (
              <Box mt="1rem">
                <Text color="red" textAlign="center">
                  {error}
                </Text>
              </Box>
            )}
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

Newsletter.propTypes = {
  bg: PropTypes.string,
  borderRadius: PropTypes.string,
};

Newsletter.defaultProps = {
  bg: "newsletter",
  borderRadius: 0,
};
