import React, { useState } from "react";
import { Box } from "rebass";
import { graphql, useStaticQuery } from "gatsby";
import SearchIcon from "@icons/search-icon.svg";
import { Container } from "../container";
import { Wrapper, List, ListItem, ListLink } from "./style";
import { Searchbar } from "./Searchbar";

export const Navigation = () => {
  const [showBar, setShowBar] = useState(false);
  const toggleSearchBar = () => setShowBar((v) => !v);

  const {
    allStrapiCategory: { edges: data },
  } = useStaticQuery(query);

  return (
    <Container>
      <Wrapper>
        {!showBar ? (
          <>
            <List>
              <ListItem>
                <ListLink activeClassName="active" partiallyActive={false} to="/">
                  Home
                </ListLink>
              </ListItem>

              {data.map(({ node }) => (
                <ListItem key={node.strapiId}>
                  <ListLink activeClassName="active" to={`/category/${node.slug}`}>
                    {node.name}
                  </ListLink>
                </ListItem>
              ))}
              <Box display={["none", null, "block"]} onClick={toggleSearchBar} sx={{ cursor: "pointer" }}>
                <SearchIcon />
              </Box>
            </List>

            <Box display={["block", null, "none"]} onClick={toggleSearchBar} sx={{ cursor: "pointer" }}>
              <SearchIcon />
            </Box>
          </>
        ) : (
          <Searchbar toggle={toggleSearchBar} />
        )}
      </Wrapper>
    </Container>
  );
};

const query = graphql`
  query {
    allStrapiCategory {
      edges {
        node {
          strapiId
          slug
          name
        }
      }
    }
  }
`;
