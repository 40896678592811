import { css } from "@emotion/core";
import styled from "@emotion/styled";

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  left: 0;
  padding: 1rem;
  position: relative;
  top: 0;
  transition: all 0.2s;
  width: 100%;

  ${({ scrolled, theme }) =>
    scrolled &&
    css`
      background-color: ${theme.colors.white};
      box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.073);
      left: 0;
      padding: 0.5rem;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 9;
    `}
`;
