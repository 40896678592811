import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import ProgressBar from "react-scroll-progress-bar";
import { Button, Flex, Text } from "rebass";
import Logo from "@icons/opay-logo.svg";
import { Wrapper } from "./style";
import { Container } from "../container";

export const TopBar = ({ title, scrolled }) => {
  return (
    <Wrapper scrolled={scrolled}>
      <Container>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <Link to="/">
              <Logo />
            </Link>

            {title && scrolled ? (
              <Text variant="body" fontWeight="bold" ml="5rem" display={["none", null, "block"]}>
                {title}
              </Text>
            ) : null}
          </Flex>
          <Button as={Link} href="/">
            Contact Us
          </Button>
        </Flex>
      </Container>

      {title && <ProgressBar bgcolor="#1DCF9F" />}
    </Wrapper>
  );
};

TopBar.propTypes = {
  scrolled: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

TopBar.defaultProps = {
  title: "",
};
