import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "rebass";
import { Banner, Footer, Navigation, Newsletter, SEO, TopBar } from "@components";

const Layout = ({ banner, children, seoTitle, showNav, postTitle, scrollOffset }) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      const offset = window.pageYOffset;
      setScrolled(offset > scrollOffset);
    };

    onScroll();
    document.addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <Box pt={scrolled ? "7rem" : 0}>
      <SEO title={seoTitle} />
      <TopBar title={postTitle} scrolled={scrolled} />
      {banner ? <Banner title={banner?.title} description={banner?.description} /> : null}
      {showNav && <Navigation />}
      {children}
      <Newsletter />
      <Footer />
    </Box>
  );
};

Layout.propTypes = {
  banner: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  postTitle: PropTypes.string,
  scrollOffset: PropTypes.number,
  seoTitle: PropTypes.string.isRequired,
  showNav: PropTypes.bool,
};

Layout.defaultProps = {
  banner: null,
  postTitle: "",
  scrollOffset: 70,
  showNav: true,
};

export default Layout;
