import React from "react";
import { Flex, Image, Text } from "rebass";
import emptyState from "@images/empty-state.png";

export const EmptyState = () => {
  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center" py={["5rem", null, "11rem"]}>
      <Image
        alt="Empty state"
        maxWidth="42rem"
        mb="4rem"
        src={emptyState}
        sx={{
          objectFit: "contain",
        }}
      />

      <Text
        textAlign="center"
        variant="body"
        sx={{
          opacity: 0.8,
        }}
      >
        Oops! No post yet...
      </Text>
    </Flex>
  );
};
