import React from "react";

export function useOnFormSuccess(time = 5000) {
  const [submitted, setSubmitted] = React.useState(false);

  React.useEffect(() => {
    let timeout;
    if (submitted) {
      timeout = setTimeout(() => {
        setSubmitted(false);
      }, time);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [submitted]);

  return [submitted, setSubmitted];
}
