import styled from "@emotion/styled";
import { Link } from "gatsby";

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
`;

export const List = styled.ul`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  width: 100%;

  ${({ theme }) => theme.mq.sm`
    justify-content: space-between;
  `}
`;

export const ListItem = styled.li`
  margin-bottom: 10px;

  ${({ theme }) => theme.mq.lg`
  margin: 0;
  `};
`;

export const ListLink = styled(Link)`
  align-items: center;
  border-radius: 4px;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.md};
  justify-content: center;
  padding: 4px 15px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s;

  &.active {
    background-color: ${({ theme }) => theme.colors.primary}0D;
    color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary}1A;
  }
`;
