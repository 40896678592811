const objectToGetParams = (object) => {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
  return params.length > 0 ? `?${params.join("&")}` : "";
};

const facebookShare = (url, quote) => {
  return `https://www.facebook.com/sharer/sharer.php${objectToGetParams({
    u: url,
    quote,
  })}`;
};

const twitterShare = (url, title) => {
  return `https://twitter.com/share${objectToGetParams({
    url,
    text: title,
  })}`;
};

const linkedinShare = (url, title) => {
  return `http://www.linkedin.com/shareArticle${objectToGetParams({
    mini: true,
    url,
    title,
  })}`;
};

export const openInNewTab = (url) => {
  window.open(url, "_blank");
};

export const share = {
  toFacebook: facebookShare,
  toLinkedin: linkedinShare,
  toTwitter: twitterShare,
};
