import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Box, Flex } from "rebass";
import Paginator from "react-hooks-paginator";
import { Card } from "./card";
import { Container } from "./container";
import { Newsletter } from "./newsletter";
import { EmptyState } from "./empty-state";

const PaginatorWrapper = styled(Box)`
  .react-hooks-paginator {
    flex-wrap: wrap;
    width: 100%;
  }

  .page-link {
    background: none;
    border: 1px solid transparent;
    color: ${({ theme }) => theme.colors.text};
    cursor: pointer;
    margin-left: 0;

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary}0D !important;
      border-color: ${({ theme }) => theme.colors.primary}1A !important;
      color: ${({ theme }) => theme.colors.primary} !important;
    }
  }

  .page-item {
    margin: 0 1rem;
    &.active {
      .page-link {
        background-color: ${({ theme }) => theme.colors.primary}0D !important;
        border-color: ${({ theme }) => theme.colors.primary}1A !important;
        border-radius: 4px;
        color: ${({ theme }) => theme.colors.primary} !important;
      }
    }
  }
`;

export const Posts = ({ data, totalCount }) => {
  const pageLimit = 6;

  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);

  const onPageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 400);
  };

  useEffect(() => {
    setCurrentData(data.slice(offset, offset + pageLimit));
  }, [offset, data]);

  const renderItem = ({ node }, index) => {
    const full = totalCount === 2 || index === 0 || index === 3 || (totalCount === 5 && index === 4);

    return (
      <>
        {index === 3 ? (
          <Box mb="3.5rem" width={1} px="1.5rem">
            <Newsletter borderRadius="10px" bg="newsletterAlt" />
          </Box>
        ) : null}

        <Box key={node.id} mb="3.5rem" width={[1, null, full ? 1 : "50%"]} height="100%" px="1.5rem">
          <Card item={node} full={full} />
        </Box>
      </>
    );
  };

  if (!totalCount) return <EmptyState />;

  return (
    <Container>
      <Box mb={["5rem", null, "9rem"]}>
        <Flex flexDirection="row" mx="-1.5rem" flexWrap="wrap">
          {currentData.map(renderItem)}
        </Flex>
      </Box>

      <PaginatorWrapper>
        <Paginator
          currentPage={currentPage}
          pageLimit={pageLimit}
          pageNeighbours={1}
          pageNextText=">"
          pagePrevText="<"
          setCurrentPage={onPageChange}
          setOffset={setOffset}
          totalRecords={totalCount}
        />
      </PaginatorWrapper>
    </Container>
  );
};

Posts.propTypes = {
  data: PropTypes.array,
  totalCount: PropTypes.number.isRequired,
};

Posts.defaultProps = {
  data: [],
};
