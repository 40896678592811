import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { Heading } from "rebass";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import OPayLogo from "@icons/opay-logo.svg";
import GooglePlayStoreIcon from "@icons/playstore-button-default.svg";
import AppleStoreIcon from "@icons/appstore-button-default.svg";
import FacebookIcon from "@icons/facebook-icon.svg";
import InstagramIcon from "@icons/instagram-icon.svg";
import LinkedinIcon from "@icons/linkedin-icon.svg";
import TwitterIcon from "@icons/twitter-icon.svg";
import YoutubeIcon from "@icons/youtube-icon.svg";
import PCIDSSIcon from "@icons/pci-dss-logo.svg";
import { Container } from "../container";

const ColumnTitle = (props) => (
  <Heading as="h6" color="header" fontSize={["md", null, "lg"]} fontWeight="semibold" textAlign="left" {...props} />
);

export const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          playstoreUrl
          applestoreUrl
          blogUrl
          facebookUrl
          twitterUrl
          instagramUrl
          linkedinUrl
          youtubeUrl
          documentationUrl
        }
      }
    }
  `);

  return (
    <FooterWrapper>
      <FooterColumnWrapper>
        <BrandWrapper>
          <OPayLogo />
          <AppDownloadIcons>
            <CTA href={data.site.siteMetadata.playstoreUrl}>
              <GooglePlayStoreIcon />
            </CTA>
          </AppDownloadIcons>
          <AppDownloadIcons>
            <CTA href={data.site.siteMetadata.applestoreUrl}>
              <AppleStoreIcon />
            </CTA>
          </AppDownloadIcons>
        </BrandWrapper>
        <FooterColumn>
          <ColumnTitle>Company</ColumnTitle>
          <ul>
            <li>
              <OutboundLink href={data.site.siteMetadata.blogUrl}>Blog</OutboundLink>
            </li>
            <li>
              <Link to="#">About Us</Link>
            </li>
            <li>
              <Link to="#">Contact Us</Link>
            </li>
          </ul>
        </FooterColumn>
        <FooterColumn>
          <ColumnTitle>Resources</ColumnTitle>
          <ul>
            <li>
              <OutboundLink href={data.site.siteMetadata.documentationUrl} target="_blank" rel="noopener noreferrer">
                Documentation
              </OutboundLink>
            </li>
            <li>
              <Link to="#">Privacy Policy</Link>
            </li>
            <li>
              <Link to="#">Terms of service</Link>
            </li>
            <li>
              <Link to="#">Agent Agreement</Link>
            </li>
            <li>
              <Link to="#">Security</Link>
            </li>
            <li>
              <Link to="#">FAQs</Link>
            </li>
          </ul>
        </FooterColumn>
        <FooterColumn>
          <ColumnTitle>Discover</ColumnTitle>
          <ul>
            <li>
              <Link to="#">FlexiFixed</Link>
            </li>
            <li>
              <Link to="#">Remittance</Link>
            </li>
          </ul>
        </FooterColumn>
        <FooterColumn>
          <ColumnTitle>Follow us</ColumnTitle>
          <SocialIconsWrapper>
            <ul>
              <li>
                <CTA href={data.site.siteMetadata.facebookUrl} aria-label="OPay facebook link">
                  <FacebookIcon />
                </CTA>
              </li>
              <li>
                <CTA href={data.site.siteMetadata.instagramUrl} aria-label="OPay instagram link">
                  <InstagramIcon />
                </CTA>
              </li>
              <li>
                <CTA href={data.site.siteMetadata.linkedinUrl} aria-label="OPay linkedin link">
                  <LinkedinIcon />
                </CTA>
              </li>
              <li>
                <CTA href={data.site.siteMetadata.twitterUrl} aria-label="OPay twitter link">
                  <TwitterIcon />
                </CTA>
              </li>
              <li>
                <CTA href={data.site.siteMetadata.youtubeUrl} aria-label="OPay youtube link">
                  <YoutubeIcon />
                </CTA>
              </li>
            </ul>
          </SocialIconsWrapper>
          <SecurityIcon as={PCIDSSIcon} />
        </FooterColumn>
      </FooterColumnWrapper>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  padding: 8rem 0;

  ${({ theme }) => theme.mq.md`
  padding:  12rem 0;
  `}
`;

const BrandWrapper = styled(Container)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-column: 1/3;
  padding-left: 0;

  ${({ theme }) => theme.mq.sm`
    grid-column: 1;
  `}
`;

const AppDownloadIcons = styled.div`
  display: none;

  ${({ theme }) => theme.mq.md`
  display: block;
    margin-top: 20px;
    svg {
      height: auto;
      max-width: 130px;
    }
  `}
`;

const FooterColumnWrapper = styled(Container)`
  display: grid;
  grid-auto-flow: dense;
  grid-gap: 32px;
  grid-template-columns: 1fr 1fr;

  ${({ theme }) => theme.mq.md`
  grid-column-gap: 16px;
    grid-template-columns: repeat(5, 1fr);
    justify-content: start;
  `}

  ${({ theme }) => theme.mq.sm`
    grid-gap: 32px;
  `}
`;
const FooterColumn = styled.div`
  ul {
    line-height: 30px;
    list-style: none;
    margin: 16px 0;
    padding: 0;
    li {
      font-weight: "${({ theme }) => theme.fontWeights.normal}";
      font-size: ${({ theme }) => theme.fontSizes.md};
      margin-bottom: 12px;
      a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.text};
      }
    }
  }
`;

const SecurityIcon = styled.svg`
  width: 80px;
  height: 30px;
`;

const SocialIconsWrapper = styled.div`
  ul {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    li {
      &:not(:last-child) {
        margin-right: 8px;
        ${({ theme }) => theme.mq.md`
          margin-right: 16px;
        `}
      }
    }
  }
`;

const CTA = styled(OutboundLink)`
  &:hover {
    opacity: 0.8;
  }
`;
