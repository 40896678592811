import React from "react";
import PropTypes from "prop-types";
import { Box, Flex, Heading, Text } from "rebass";

export const Banner = ({ description, title }) => {
  return (
    <Flex bg="banner" px="1.5rem" py={["7rem", null, "13rem"]} alignItems="center" justifyContent="center">
      <Box maxWidth="48rem">
        <Heading textAlign="center" mb="1.5rem" as="h1" fontSize={["4xl", null, "5xl"]}>
          {title}
        </Heading>
        <Text textAlign="center" variant="body" fontSize={["md", null, "xl"]}>
          {description}
        </Text>
      </Box>
    </Flex>
  );
};

Banner.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
