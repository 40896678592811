import styled from "@emotion/styled";

export const Container = styled.div`
  margin: 0 auto;
  padding: 0 16px;
  width: 100%;

  ${({ theme }) => theme.mq.sm`
  max-width: 540px;
  `}

  ${({ theme }) => theme.mq.md`
  max-width: 720px;
  `}

  ${({ theme }) => theme.mq.lg`
  max-width: 960px;
  `}

  ${({ theme }) => theme.mq.xl`
  max-width: 1200px;
  `}
`;
