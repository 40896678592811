import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { Button } from "rebass";
import NotFoundIcon from "../../assets/icons/not-found.svg";
import Cloud from "../../assets/icons/cloud.svg";
import Clouds from "./clouds";

const Wrapper = styled.div`
  padding: 4rem 0;
`;

const Flex = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PosterWrapper = styled.div`
  position: relative;

  .cloud {
    display: none;
    position: absolute;
    top: 40px;
    width: 55px;
    ${({ theme }) => theme.mq.lg`
    display: block;
    `}

    &__1 {
      left: -100px;
    }

    &__2 {
      right: -70px;
    }
  }
`;

const Poster = styled.svg`
  height: auto;
  margin-bottom: 70px;
  max-width: 100%;
`;

const Caption = styled.p`
  ${({ theme }) => theme.fontSizes.lg};
  color: #494949;
  font-family: 300;
  margin-bottom: 2rem;
  text-align: center;

  ${({ theme }) => theme.mq.sm`
  font-size: 18px;
  line-height: 30px;
  `}
`;

const CTASection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 420px;
  width: 100%;
`;

export const NotFound = () => {
  return (
    <Wrapper>
      <Flex>
        <Clouds />
        <PosterWrapper>
          <Cloud className="cloud cloud__1" />
          <Poster as={NotFoundIcon} />
          <Cloud className="cloud cloud__2" />
        </PosterWrapper>
        <CTASection>
          <Caption>Sorry, we can’t find that page! It might be an old link or maybe it has been removed.</Caption>
          <Button as={Link} to="/">
            Return to homepage
          </Button>
        </CTASection>
      </Flex>
    </Wrapper>
  );
};
