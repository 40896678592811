import React from "react";
import PropTypes from "prop-types";
import { Box, Flex } from "rebass";
import styled from "@emotion/styled";
import CloseIcon from "@icons/close.svg";
import SearchIcon from "@icons/search-icon.svg";
import { useSearch } from "@hooks";

const Input = styled.input`
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid rgba(165, 172, 192, 0.3);
  caret-color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.md};
  height: 55px;
  line-height: 19px;
  outline: none !important;
  padding: 10px 10px 10px 65px;
  transition: all 0.2s;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.colors.text}80;
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 2px #1dcf9f1a;
  }
`;

const SearchWrapper = styled(Box)`
  left: 2.6rem;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const Searchbar = ({ toggle }) => {
  const { searchQuery, setQuery } = useSearch();

  return (
    <Flex width={1} alignItems="center">
      <Box width={1} mr={["3rem"]} sx={{ position: "relative" }}>
        <Input placeholder="Search Blog" value={searchQuery} onChange={(e) => setQuery(e.target.value)} />
        <SearchWrapper>
          <SearchIcon opacity={0.5} />
        </SearchWrapper>
      </Box>
      <Box sx={{ cursor: "pointer" }} onClick={toggle}>
        <CloseIcon />
      </Box>
    </Flex>
  );
};

Searchbar.propTypes = {
  toggle: PropTypes.func.isRequired,
};
